<template>
  <div>
    <search-filter
        :items="contacts"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :addMapView="true"
        @addItem="addContact"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-contact
            :contact="item"
            @click="selectContact(item)"
            @deleteContact="deleteContactAlert(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{ item._display }}
      </template>

      <!--      Cell email-->
      <template #listView_cell_email="{item}">
        <a :href="'mailto:'+item.email">{{ item.email }}</a>
      </template>

      <!--      Cell phone-->
      <template #listView_cell_phone="{item}">
        <a :href="'tel:'+item.phone">{{ item.phone }}</a>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit-->
          <button-edit
              @click="selectContact(item)"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-delete
              @click.native.stop="deleteContactAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              :disabled="!item._isDeletable"
          />

        </div>
      </template>

    </search-filter>


    <!-- modal contact-->
    <modal-contact
        :contact.sync="currentContact"
        :title="contactModalTitle"
        :isContactEdit="isContactEdit"
        :isOpen.sync="contactModalShow"
        @submitValidatedContact="submitValidatedContactLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinContacts } from './mixinContacts'

import i18n from '@/libs/i18n'
import useAPI from '../../utils/useAPI'
import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'
import { capitalize } from '../../utils/filter'

export default {
  components: {
    ButtonEdit,
    ButtonDelete
  },
  mixins: [mixinContacts],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCompany',
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isUser',
      },
    ])

    const columns = ref([
      {
        display: capitalize(i18n.t('contact')),
        key: 'contact',
        sortable: true
      },
      {
        display: capitalize(i18n.t('email')),
        key: 'email',
        sortable: true
      },
      {
        display: capitalize(i18n.t('phone')),
        key: 'phone',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { contacts } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      sortOptions,
      columns,

      // Computed
      contacts,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>